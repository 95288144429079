import { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import hammerLeft from '../images/btn_hammer_left.png';
import hammerCenter from '../images/btn_hammer_center.png';
import hammerRight from '../images/btn_hammer_right.png';

const ResetCSS = createGlobalStyle`
  html {
    font-size: 15px;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  span,
  div {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    margin-top: 0;
    font-family: 'PT Serif',sans-serif;
  }

  body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    font-size: 15px;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
    background-color: ${themeGet('colors.primaryBackground', '#000')};
  }

  p{
    line-height: 2;
    margin: 0 0 1.7em 0;
  }

  input, textarea, select, button{font-family: 'Roboto', sans-serif;}

  ul,ol {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    box-shadow: none;
    color: #b96d72;
  }

  a:hover {
    text-decoration: none;
  }

  blockquote{
    font-family: 'PT Serif',sans-serif;
    font-weight: 500;
  }

  :not(pre) > code[class*="language-"], pre[class*="language-"] {
    background: #f5f5f5;
  }

  pre[class*="language-"] {
    padding: 1.5em;
    margin: 2em 0;
    overflow: auto;
  }

  table {
    display: block;
    overflow: auto;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 2.7em;
    line-height: 1.6;
  }

  table tr {
    background-color: #fff;
    border-top: 1px solid #c6cbd1;
  }

  table tr:nth-child(2n) {
    background-color: #f6f8fa;
  }

  table th{
    font-weight: 500;
  }

  table td,
  table th {
    border: 1px solid #dfe2e5;
    padding: .4em 1em;
  }

  .nav-sticky{
    .header {
      box-shadow: 0 0 15px rgb(0 0 0);
      .navbar{
        @media (min-width: 1400px) {
          padding-top: 25px;
          padding-bottom: 25px;
        }
        @media (min-width: 1200px) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }
  }

  .button-container {
    text-align: center;
  }

  .btn-hammer {
    position: relative;
    display: inline-block;
    height: 43px !important;
    top: 15px;
    background: url(${hammerCenter});
    background-position-y: -8px;
    margin-bottom: 30px !important;
    margin-left: 42px;
    margin-right: 42px;
    filter: brightness(0.9);
    &:hover {
      filter: brightness(1);
      a {
        color: #fff;
      }
    }
    &:before {
      content: '';
      position: absolute;
      left: -42px;
      top: -9px;
      width: 44px;
      height: 60px;
      background: url(${hammerLeft});
    }
    &:after {
      content: '';
      position: absolute;
      right: -42px;
      top: -9px;
      width: 44px;
      height: 60px;
      background: url(${hammerRight});
    }
    a {
      background: transparent !important;
      color: #fff;
      padding: 13px 10px !important;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      &:hover {
        background: transparent !important;
      }
    }
  }
  button.btn-hammer {
    padding: 0 5px !important;
  }
  .image-link-highlight {
    transition: 0.24s ease-in-out;
    box-shadow: 0 0 15px rgb(0 0 0);
    @media (max-width: 900px) {
      max-width: 100%;
      height: auto;
    }
    &:hover {
      border-radius: 4px;
      transform: scale(1.3);
    }
  }
  .multi-image + p {
    text-align: center;
  }

  ul li {
    color: #cecac3;
  }
`;
export default ResetCSS;
